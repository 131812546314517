import { FC, useEffect } from 'react'

import { styled } from '@liftfoils/styles'
import {
  CollectionProductCardProps,
  CollectionPromoTile,
  SectionCollectionProps,
} from '@liftfoils/models'
import { Container, Link, Media, ProductCard } from '@liftfoils/components'
import { GTMViewItemList } from '@liftfoils/services/gtm-service'
import { useRouter } from 'next/router'
import { validateLink } from '../Helpers/validateLink'

const Wrap = styled('div', {
  $py: 'L',
  variants: {
    priority: {
      true: {
        $pt: 'XXL',
        $pb: 'L',
      },
    },
  },
})

const SectionGrid = styled('div', {
  display: 'grid',
  gridGap: '$7',
  '& + div': {
    mt: '$10',
  },
})

const GridHeader = styled('div', {
  display: 'flex',
})

const GridHeading = styled('div', {
  lift_font: 'heading02',
  color: '$gray500',
  mb: '$5',
})

const Grid = styled('div', {
  display: 'grid',
  gridGap: '$6',
  gridTemplateColumns: 'repeat(2, 1fr)',
  '@sm': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@lg': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
})

const PromoCardWrap = styled('div', {
  position: 'relative',
  paddingBottom: '120%',
})
const PromoCard = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: '$r1',
  overflow: 'hidden',
  display: 'grid',
})

const ImageWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
})
const PromoLabel = styled('h2', {
  lift_font: 'caps03',
  color: '$white',
  padding: '$6',
  zIndex: 1,
  position: 'relative',
  alignSelf: 'center',
  justifySelf: 'center',
  gridRow: 1,
  gridColumn: 1,
})

const isProductCard = (
  item: CollectionProductCardProps | CollectionPromoTile,
): item is CollectionProductCardProps => {
  return item._type === 'product'
}

export const SectionCollection: FC<SectionCollectionProps> = ({
  subcollections,
  shopifyData,
  title,
  priority,
}) => {
  const { locale } = useRouter()

  useEffect(() => {
    if (!shopifyData) {
      return
    }

    subcollections?.forEach((subcollection) => {
      const products = subcollection.items
        .filter(isProductCard)
        .map((item) => shopifyData.productBasic?.[item.id])
        .filter((item) => !!item)

      if (products.length) {
        GTMViewItemList(
          products,
          'section_collection',
          subcollection.title || 'Sub collection',
        )
      }
    })
  }, [locale, shopifyData, subcollections, title])

  if (!shopifyData) {
    return null
  }

  return (
    <Wrap priority={priority}>
      <Container>
        {subcollections &&
          subcollections.map((collection, index1) => (
            <SectionGrid
              key={`${collection._key}-${index1}`}
              id={`collection-${collection._key}`}
            >
              {collection.items.length > 1 && (
                <GridHeader>
                  <GridHeading>{collection.title}</GridHeading>
                </GridHeader>
              )}
              <Grid>
                {collection.items.map((item, index2) => {
                  const { _type } = item

                  if (_type === 'efoil' || _type === 'product') {
                    const product =
                      shopifyData.productBasic &&
                      shopifyData.productBasic[item.id]

                    if (!product) return null

                    return <ProductCard product={product} key={product.id} />
                  }

                  if (_type === 'promoTile') {
                    return (
                      <PromoCardWrap key={`${index1}-${index2}`}>
                        <PromoCard
                          {...(validateLink(item.link)
                            ? { as: Link, ...item.link }
                            : {})}
                        >
                          {item.background && (
                            <ImageWrap>
                              <Media
                                {...item.background}
                                objectFit="cover"
                                layout="fill"
                                hardcropRatio="portrait1"
                                sizes="(min-width: 1000px) 32vw, 50vw"
                                alt={item?.background?.alt ?? ''}
                              />
                            </ImageWrap>
                          )}

                          {item.label && <PromoLabel>{item.label}</PromoLabel>}
                        </PromoCard>
                      </PromoCardWrap>
                    )
                  }
                  return null
                })}
              </Grid>
            </SectionGrid>
          ))}
      </Container>
    </Wrap>
  )
}
