import { GTMResetEcommerce } from './gtmResetEcommerce'
import {
  ShopifyResolvedProductBasic,
  ShopifyResolvedProductExtended,
} from '@liftfoils/shopify-sdk'

export const GTMViewItemList = (
  products: ShopifyResolvedProductBasic[] | ShopifyResolvedProductExtended[],
  listId: string,
  listName: string,
) => {
  GTMResetEcommerce()

  const w = window as any
  w.dataLayer = w.dataLayer || []

  const data = {
    event: 'view_item_list',
    ecommerce: {
      items: products.map((product, index) => {
        return {
          item_name: product.title,
          index,
          item_list_id: listId,
          item_list_name: listName,
        }
      }),
    },
  }

  w.dataLayer.push(data)
}
